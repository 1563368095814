<template>
    <div>
        <h6 class="text-uppercase font-weight-regular" v-if="lists.length">
            {{name == 'p2p' ? 'Push to Paint' : name}}
        </h6>
        <v-row class="ma-0" align="center" justify="space-between" v-if="lists.length">
            <v-col cols="6" class="font-weight-bold black--text">Item:</v-col>
            <v-col cols="6" class="font-weight-bold d-flex align-center justify-end black--text">Amount:</v-col>
        </v-row>
        <template v-for="item in lists">
            <div :key="item[name === 'products' ? 'value' : 'panel_id']"
                v-if="name !== 'pdr' || item.category">
                <v-row class="ma-0" align="center" justify="space-between">
                    <v-col cols="6" class="py-1">
                        <span class="text-uppercase">{{ item[name === 'products' ? 'text' : 'label'] }}</span>
                        <span class="ml-1" v-if="name === 'pdr'">({{item.category}})</span>
                    </v-col>
                    <v-col cols="6" class="d-flex align-center justify-end py-1">
                        {{ item[name === 'products' ? 'price' : 'lineTotal'] ?
                            Number(item[name === 'products' ? 'price' : 'lineTotal']).toFixed(2) : 0}}
                    </v-col>
                </v-row>
            </div>
        </template>
        <v-row class="ma-0" align="center" justify="space-between" v-if="lists.length">
            <v-col cols="6"></v-col>
            <v-col cols="6" class="font-weight-bold d-flex align-center justify-end black--text">
                <span class="mr-3 ">Subtotal</span>
                {{total ? Number(total).toFixed(2) : '0'}}
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: ['lists', 'name', 'total']
}
</script>

<style>

</style>